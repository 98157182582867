import $ from 'jquery';
import './_bootstrap';
import './slick';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
(function () {
	// Biz App Carousel

	$('.banner-image-carosel').slick({
		dots: true,
		slidesToShow: 1,
		arrows: false,
		slidesToScroll: 1,
		autoplay: false,
		centerMode: false,
	});

	$('.case-studies-slider').slick({
		slidesToShow: 2, // Show 2 slides at a time on desktop
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		centerMode: false,
		autoplay: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2, // Show 3 slides at a time for larger screens
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1, // Show 1.5 slides at a time for smaller screens
					slidesToScroll: 1,
					centerMode: false,
					centerPadding: '20px',
				},
			},
		],
	});

	var instax = instax || {};
	let $detailMobileCarousel = $('.detail-mobile-carousel');
	let $detailImageCarousel = $('.detail-image-carousel');
	gsap.registerPlugin(ScrollTrigger);

	//update product image
	instax.updateProductImage = function (settings) {
		$(settings.element)
			.attr('src', settings.url)
			.attr('alt', settings.alt)
			.attr('width', settings.width / 2)
			.attr('height', settings.height / 2)
			.parent()
			.attr('data-index', settings.index);
		$(settings.hoverElement).attr('src', settings.hoverUrl);
	};

	// image variation selector
	instax.setProductToggle = function () {
		$('.detail-mobile-carousel .feature-container').remove();
		let $features = $($('.feature-container')[0]).clone();
		let imgIndex = 3;
		$('[data-color-variation]').on('click', function () {
			let $this = $(this);
			let color = $this.data('color-variation');
			let colorNameVal = $this.data('color-variation-name');
			let post = $this.data('target-id');

			let imgHTMLContainer =
				'<div data-index="" data-bs-toggle="modal" data-bs-target="#detailCarouselImages" class="detail-img-wrap"><img src="" alt="" width="" height="" class="product-img-variation"></div>';
			let imgHTMLContainerDetail =
				'<div class="detail-img-wrap"><img src="" alt="" width="" height="" class="product-img-variation"></div>';

			let imgHTML = '';
			let imgHTMLDetail = '';

			$(`[data-post-id="${post}"] [data-color-name]`).html(colorNameVal);
			$(`[data-post-id="${post}"] [data-color-variation]`).removeClass('active');

			$this.addClass('active');
			$detailMobileCarousel.slick('unslick');
			$detailImageCarousel.slick('unslick');

			imageMap.map(function (item) {
				let key = Object.keys(item)[0];
				if (key == post) {
					// handle varable number of images for detail page
					let $targetImgGroup = $(`[data-post-id="${key}"] .product-img .product-img-variation`);
					let $targetImgGroupHover = $(`[data-post-id="${key}"] .product-img .product-img-variation-hover`);
					let $targetImgGroupMobile = $(
						`[data-post-id="${key}"] .product-img.detail-mobile-carousel .product-img-variation`,
					);
					let $targetImgGroupDetailModal = $(`.detail-image-carousel .product-img-variation`);

					if ($targetImgGroup.length !== item[key][color].length) {
						$('.feature-container').remove();

						// rebuild html
						item[key][color].map(function (data, i) {
							imgHTML += imgHTMLContainer;
							imgHTMLDetail += imgHTMLContainerDetail;
						});

						// inject html for page
						$(`[data-post-id="${key}"] .product-img, [data-post-id="${key}"] .product-img.detail-mobile-carousel`).html(
							imgHTML,
						);
						// inject html for detail modal
						$(`.detail-image-carousel`).html(imgHTMLDetail);

						// retarget elements
						$targetImgGroup = $(
							`[data-post-id="${key}"] .product-img .product-img-variation:not(.detail-mobile-carousel .product-img-variation)`,
						);
						$targetImgGroupMobile = $(
							`[data-post-id="${key}"] .product-img.detail-mobile-carousel .product-img-variation`,
						);
						$targetImgGroupDetailModal = $(`.detail-image-carousel .product-img-variation`);

						let targetImgGroupLen = $targetImgGroup.length - 1;

						// add the features back in after the 4th image
						$features.insertAfter(
							$($targetImgGroup[targetImgGroupLen < imgIndex ? targetImgGroupLen : imgIndex]).parent(),
						);
					}

					item[key][color].map(function (data, i) {
						instax.updateProductImage({
							element: $targetImgGroup[i],
							id: key,
							url: data.url,
							alt: data.alt,
							width: data.width,
							height: data.height,
							index: i,
							hoverElement: $targetImgGroupHover[i],
							hoverUrl: data['hover-url'],
						});
						instax.updateProductImage({
							element: $targetImgGroupMobile[i],
							id: key,
							url: data.url,
							alt: data.alt,
							width: data.width,
							height: data.height,
							index: i,
						});
						instax.updateProductImage({
							element: $targetImgGroupDetailModal[i],
							id: key,
							url: data.url,
							alt: data.alt,
							width: data.width,
							height: data.height,
							index: i,
						});
					});
				}
			});
			instax.detailMobileCarousel();
			instax.detailCarousel();
			instax.detailSidebarScrollTrigger.kill();
			instax.stickyDetailSidebar();
		});
	};
	instax.detailMobileCarousel = function () {
		$detailMobileCarousel.slick({
			dots: true,
			slidesToShow: 1,
			arrows: false,
			slidesToScroll: 1,
			autoplay: false,
		});
	};

	instax.headerNavigation = function () {
		// header navigation hover toggle
		$('.site-header .camera-dropdown').hover(
			function () {
				if ($(window).width() > 991) {
					$('.camera-products').addClass('show-dropdown');
				}
			},
			function () {
				$('.camera-products').removeClass('show-dropdown');
			},
		);
		$('.site-header .printer-dropdown').hover(
			function () {
				if ($(window).width() > 991) {
					$('.printer-products').addClass('show-dropdown');
				}
			},
			function () {
				$('.printer-products').removeClass('show-dropdown');
			},
		);
		// Hacky hover to keep div active while hoving over subnav
		$('.camera-products').hover(
			function () {
				$('.camera-products').addClass('show-dropdown');
			},
			function () {
				$('.camera-products').removeClass('show-dropdown');
			},
		);
		$('.printer-products').hover(
			function () {
				$('.printer-products').addClass('show-dropdown');
			},
			function () {
				$('.printer-products').removeClass('show-dropdown');
			},
		);
		// Navbar click handler for mobile subnav
		$('.site-header .camera-dropdown').click(function (e) {
			if ($(window).width() > 991) {
				$('.camera-products').toggleClass('show-dropdown');
			} else {
				e.preventDefault();
				$('.camera-products').toggleClass('show-dropdown');
			}
		});
		$('.site-header .printer-dropdown').click(function (e) {
			if ($(window).width() > 991) {
				$('.printer-products').toggleClass('show-dropdown');
			} else {
				e.preventDefault();
				$('.printer-products').toggleClass('show-dropdown');
			}
		});
		$('.site-header .dropdown').click(function (e) {
			if ($(window).width() < 991) {
				e.preventDefault();
				$('#nav-return-dynamic')[0].innerText = e.target.innerText;
				$('.dropdown-menu')
					.click(function (e) {
						e.stopPropagation();
					})
					.addClass('show-dropdown');
				$('.dynamic-mobile-dropdown-header').addClass('show-dropdown');
			}
		});
		// Subnav button to return to main nav
		$('.nav-return').click(function (e) {
			$('.camera-products').removeClass('show-dropdown');
			$('.printer-products').removeClass('show-dropdown');
			$('.dynamic-mobile-dropdown-header').removeClass('show-dropdown');
			$('.dropdown-menu').removeClass('show-dropdown');
		});
		// Hide subnav when close main navbar
		$('.navbar-toggler').click(function (e) {
			$('.camera-products').removeClass('show-dropdown');
			$('.printer-products').removeClass('show-dropdown');
			$('.dynamic-mobile-dropdown-header').removeClass('show-dropdown');
			$('.dropdown-menu').removeClass('show-dropdown');
		});

		$('#view-all-btn').each(function () {
			$(this).click(function () {
				$(this).toggleClass('white-btn-caret-active');
				$('.specs-block-container-overflow').toggleClass('hide');
				if ($(this).text().toLowerCase().includes('view')) {
					$(this).text('Hide Specifications');
				} else {
					$(this).text('View All Specifications');
				}
			});
		});
	};

	instax.setRetailerModalEvent = function () {
		let lastUrl = '';
		let $retaileriFrame = $('#retailers-iframe');
		$('#retailModalCenter').on('show.bs.modal', function (event) {
			let $this = $(this);
			let url = $(event.relatedTarget).data('retail-link');
			if (lastUrl != url) {
				$retaileriFrame.attr('src', url);
			}
			lastUrl = url;
		});
	};

	instax.setRetailerButton = function () {
		let $retailButton = $('.product-ancillary-page-navigation .retailer-btn');
		if ($retailButton.length > 0) {
			$retailButton.attr({
				'data-bs-toggle': 'modal',
				'data-bs-target': '#retailModalCenter',
				'data-retail-link': $('[data-retail-link]')[0].getAttribute('data-retail-link'),
			});
		}
		instax.setRetailerModalEvent();
	};

	instax.detailCarousel = function () {
		$detailImageCarousel.slick({
			dots: true,
			slidesToShow: 1,
			arrows: false,
			slidesToScroll: 1,
			autoplay: false,
			//autoplaySpeed: 2000,
			arrows: true,
			centerMode: true,
			adaptiveHeight: true,
			customPaging: function (slick, index) {
				return '<button>' + $(slick.$slides[index]).html() + '</button>';
			},
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});

		// prevent modal opening on mobile
		$('#detailCarouselImages').on('show.bs.modal', function (e) {
			let index = $(e.relatedTarget).attr('data-index');
			$detailImageCarousel.slick('slickGoTo', index);

			if (window.innerWidth < 992) {
				return e.preventDefault();
			}
		});
		$('#detailCarouselImages').on('shown.bs.modal', function (e) {
			$detailImageCarousel.slick('refresh');
		});
	};

	instax.titleRegmark = function () {
		//fix reg marks in product titles
		$(`h1,
      .product-header,
      .nav-link,
      .entry-title,
      .card-product-title`).each(function () {
			let html = $(this)[0]
				.innerHTML.replace(/((?!<sup>\s*))&reg;((?!\s*<\/sup>))/gi, '<sup>&reg;</sup>')
				.replace(/((?!<sup>\s*))®((?!\s*<\/sup>))/gi, '<sup>&reg;</sup>')
				.replace(/((?!<sup>\s*))™((?!\s*<\/sup>))/gi, '<sup>&trade;</sup>')
				.replace(/((?!<sup>\s*))&trade;((?!\s*<\/sup>))/gi, '<sup>&trade;</sup>');

			$(this)[0].innerHTML = html;
		});
	};

	instax.filmSwatchHover = function () {
		let $color = $('.color-name');
		let colorName = $color.text();
		$('[data-swatch-color-name]').hover(
			function () {
				$color.text($(this).data('swatch-color-name'));
			},
			function () {
				$color.text(colorName);
			},
		);
	};

	instax.externalGate = function () {
		let url;
		$('.external-url').on('click', function (e) {
			e.preventDefault();
			e.stopPropagation();
			url = $(this).attr('href');
			$('#externalSite').modal('show');
		});
		$('[data-proceed]').on('click', function (e) {
			window.open(url);
		});
	};

	instax.stickyDetailSidebar = function () {
		
		let mm = gsap.matchMedia();
		mm.add('(min-width: 768px)', function () {
			instax.detailSidebarScrollTrigger = ScrollTrigger.create({
				trigger: '#gallery',
				start: 'top top+=80px',
				end: function () {
					let sidebarHeight = $('#detail-sidebar').height();
					let galleryHeight =
						$('#gallery').height() > $('#detail-sidebar').height()
							? $('#gallery').height()
							: $('.product-img').height();
					return Math.round(galleryHeight - sidebarHeight);
				},
				pin: '#detail-sidebar',
			});
		});
	};

	instax.stickyTruckTourMap = function () {
		let mm = gsap.matchMedia();
		mm.add('(min-width: 768px)', function () {
			instax.detailSidebarScrollTrigger = ScrollTrigger.create({
				trigger: '#tour-map',
				start: 'top top+=80px',
				endTrigger: '.wrapper_tracker_banner',
				end: 'bottom bottom',
				pin: true,
				onUpdate: self => {
					self.refresh();
				}
			});
		});
	};

	instax.stickyAnciliaryNav = function () {
		let mm = gsap.matchMedia();

		mm.add('(min-width: 992px)', function () {
			ScrollTrigger.create({
				trigger: '#product-ancillary-nav',
				start: 'top top+=60px',
				endTrigger: '.product-email-subscription',
				pin: true,
				pinSpacing: false,
			});
			ScrollTrigger.create({
				trigger: '#biz-app-ancillary-nav',
				start: 'top top+=60px',
				endTrigger: '.product-email-subscription',
				pin: true,
				pinSpacing: false,
			});	
		});
		mm.add('(max-width: 991px)', function () {
			ScrollTrigger.create({
				trigger: '#product-ancillary-nav',
				start: 'top top+=50px',
				endTrigger: '.product-email-subscription',
				pin: true,
				pinSpacing: false,
			});
			ScrollTrigger.create({
				trigger: '#biz-app-ancillary-nav',
				start: 'top top+=50px',
				endTrigger: '.product-email-subscription',
				pin: true,
				pinSpacing: false,
			});
			
		});
	};

	instax.givesquadImageCollage = function () {
		let offsetX = 100;
		let offsetY = 100;
		let vpw = $('.influencer-img-container').width() / 2 - offsetX;
		let vph = $('.influencer-img-container').height() / 2 - offsetY;
		gsap.to('.influencer-img, .color-card', 2, {
			scrollTrigger: '.influencer-img-container',
			rotate: `random(-45, 45)`,
			x: `random(${-vpw},${vpw})`,
			y: `random(${-vph},${vph})`,
			ease: 'Expo.easeOut',
		});
		$('.influencer-img').hover(
			function () {
				gsap.to($(this), 1, {
					rotate: '+=10',
					ease: 'Expo.easeOut',
				});
			},
			function () {
				gsap.to($(this), 1, {
					rotate: '-=10',
					ease: 'Expo.easeOut',
				});
			},
		);
	};

	instax.inspirationPageTabs = function () {
		$('.idea-tabs').click(function () {
			$('.idea-tabs').addClass('active');
			$('.tip-tabs').removeClass('active');
		});
		$('.tip-tabs').click(function () {
			$('.tip-tabs').addClass('active');
			$('.idea-tabs').removeClass('active');
		});
	};

	instax.featureImgAnimations = function () {
		let xPos = 30;
		let yPos = 30;

		// create a trigger for each element
		const triggers = document.querySelectorAll('.feature-img img');
		triggers.forEach((trigger) => {
			let transformOrigin = 'center';
			const parentClassList = trigger.parentElement.classList;
	
			if (parentClassList.contains('top-right')) {
				transformOrigin = 'top right';
				xPos = 0;
				yPos = 0;
			} else if (parentClassList.contains('top-left')) {
				transformOrigin = 'top left';
				xPos = 0;
				yPos = 0;
			}
	
			gsap.from(trigger, 2, {
				x: xPos,
				y: yPos,
				scale: 0.9,
				ease: 'Expo.easeOut',
				transformOrigin: transformOrigin,
				onComplete: function () {
					ScrollTrigger.refresh();
				},
				scrollTrigger: {
					trigger: trigger,
					start: 'top 60%',
					end: 'bottom 20%',
					scrub: true,
					toggleActions: 'play none none none',
				},
			});
			xPos *= -1; // flip the x position for the next element
		});
	};

	instax.checkCompareUrl = function () {
		// compareurl set in template-parts > global-detail-product-structure.php
		if (typeof compareurl !== 'undefined') {
			const button = document.querySelectorAll('#navbarAncillaryNav .compare-btn')[0];
			button.href = compareurl;
		}
	};

	instax.contactFormDataLayerPush = function () {
		var doubleBool = false;
		function sendDataLayerPush() {
			window.dataLayer = window.dataLayer || [];
			// console.log('data', window.dataLayer)
			window.dataLayer.push({
				event: 'gen_contact_form_event',
				formCategory: 'ContactForm',
				formAction: 'Submit',
			});
		}

		// jQuery( document ).on( 'nfFormSubmitResponse', function(response) {
		jQuery(document).on('nfFormReady', function (response) {
			if (typeof Marionette !== 'undefined') {
				//
				// var nfRadioChannel = Backbone.Radio.channel('forms');

				nfRadio.channel('forms').on('submit:response', function (form) {
					// form_id's
					// 1 === contact us
					// 2 === email subscription
					// ie: form.data.form_id === 1 (is for contact us form)
					// if (form.errors && form.errors.length === 0) {
					if (form.errors && form.errors.length === 0 && form.data.form_id === '1' && doubleBool === false) {
						sendDataLayerPush();
						doubleBool = true;
					}
				});
			}
		});
	};

	instax.bazaarVoice = function () {
		setTimeout(() => {
			if (
				$('.bv_numReviews_component_container .bv_text').is('.bv_numReviews_component_container .bv_text') &&
				$('.bv_numReviews_component_container .bv_text')[0].innerHTML === '(0)'
			) {
				$('#bv-review-link').addClass('hide');
				$('.bv-rating-none').addClass('hide');
				$('#bv-review-custom-button')
					.removeClass('hide')
					.on('click', function () {
						$('#first-to-write').click();
					});
			} else {
				$('#bv-review-link').removeClass('hide');
			}

			$('.bv-content-details-container').each(function (index, item) {
				$('.bv-author-profile')[index].append(item);
			});

			$('.bv-write-review-container').appendTo($('.bv-section-summary-inline'));
			$('.bv-write-review-container').prepend(
				"<div class='bv-stars-review-container'> <div class='bv-stars-container-custom'><div class='bv-single-star'>★</div> <div class='bv-single-star'>★</div> <div class='bv-single-star'>★</div> <div class='bv-single-star'>★</div> <div class='bv-single-star'>★</div></div></div>",
			);
			$('.bv-write-review').appendTo('.bv-stars-review-container');
			$('.bv-write-review-container').prepend("<h3 class='bv-content-title'>Review this Product</h3>");
			$('.bv-write-review-container').append(
				"<p class='bv-review-helper'>Adding a review will require a valid email for verification</p>",
			);
			$('#bv-review-section').prepend($('.bv-action-bar'));
			$('.bv-secondary-summary').prepend($("<h3 class='bv-content-title overall-rating'>Overall Rating</h3>"));

			// Every time the page is cycled the blocks move back to their original position. This click event will reapply the repositioning.
			$('#bv-review-section').on('click', '.bv-content-btn-pages-first', function () {
				setTimeout(() => {
					$('.bv-content-details-container').each(function (index, item) {
						$('.bv-author-profile')[index].append(item);
					});
				}, 1000);
			});
			$('#bv-review-section').on('click', '.bv-content-btn-pages-last', function () {
				setTimeout(() => {
					$('.bv-content-details-container').each(function (index, item) {
						$('.bv-author-profile')[index].append(item);
					});
				}, 1000);
			});

			$('.bv-stars-container-custom').on('click', function () {
				$('.bv-write-review').click();
			});
		}, 1500);
	};

	instax.marquisBanner = function () {
		$('.marquis-banner').append('<button type="button" class="btn-close marquis-btn"></button>');
		$('.marquis-btn').on('click', function () {
			$('.marquis-banner').css('display', 'none');
		});
	};

	instax.bizapp = function () {

		var footerDiv = document.getElementById('biz-aap-footer');

		if (footerDiv) {

			var anchor1 = document.createElement('a');
			var anchor2 = document.createElement('a');
		
			anchor1.href = 'https://apps.apple.com/us/app/instax-biz/id1634173181'; 
			anchor2.href = 'https://play.google.com/store/apps/details?id=com.fujifilm.instaxBiz';
			anchor1.target = '_Blank';
			anchor2.target = '_Blank';
			anchor1.id = 'instax-link-apple';
			anchor2.id = 'instax-link-google';
			anchor1.classList.add('app-btn');
			anchor2.classList.add('app-btn');
		
			footerDiv.appendChild(anchor1);
			footerDiv.appendChild(anchor2);
		}
	}

	// kick off
	instax.init = function () {
		instax.setProductToggle();
		instax.detailMobileCarousel();
		instax.setRetailerButton();
		instax.detailCarousel();
		instax.headerNavigation();
		instax.filmSwatchHover();
		instax.titleRegmark();
		instax.stickyDetailSidebar();
		if($('.wrapper_tracker_banner').length > 0) {
			instax.stickyTruckTourMap();
		}
		instax.stickyAnciliaryNav();
		instax.externalGate();
		instax.givesquadImageCollage();
		instax.inspirationPageTabs();
		instax.checkCompareUrl();
		instax.featureImgAnimations();
		instax.contactFormDataLayerPush();
		instax.bazaarVoice();
		instax.marquisBanner();
		instax.bizapp();
	};
	instax.init();

	$(window).resize(function () {
		instax.givesquadImageCollage();
	});

	let heroVideo = jQuery('.hero-video video')[0];
	if (heroVideo) {
		heroVideo.setAttribute('controls', 'controls');
	}

	// Fix for dynamically changing the URL of links to accomodate the /wordpress pathing.
	$(document).ready(function() {
		$(".detail-partial a").each(function() {
			var href = $(this).attr("href"); 	//href from CMS
			const origin = window.location.origin;

			if (window.location.pathname.startsWith("/wordpress")) {
				$(this).attr("href", origin + '/wordpress' + href);
			} else {
				$(this).attr("href", origin + href);
			}

			
		});
	});


	console.log('instaxus.js loaded');
})();
